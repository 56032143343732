import '../styles/main.scss';
import * as bootstrap from 'bootstrap';

import 'jquery-mask-plugin';

import 'datatables.net';
import 'jquery-datatables-checkboxes';
import pt_BR from './inc/datatables-pt-br.js';
import 'slick-carousel';

import Swal from 'sweetalert2';
import { data } from 'jquery';


const base_url = document.querySelector("meta[property='ga:base_url']").getAttribute("content");
const b2c_url = document.querySelector("meta[property='ga:b2c_url']").getAttribute("content");
const user_logged_in = document.querySelector("meta[property='ga:user_logged_in']").getAttribute("content");

const header = document.getElementById('header');
const main = document.getElementById('main');
if(header && main) {
    let setBodyPaddingTop = () => {
        let headerHeight = header.offsetHeight;
        main.setAttribute('style', 'padding-top:'+ headerHeight +'px');
    }

    setBodyPaddingTop();
    window.addEventListener('resize', setBodyPaddingTop, true);
}

function OnePushInit()
{
    const dontAskAgain = document.cookie.split('; ').find(row => row.startsWith('dontAskAgainPush='));

    if (dontAskAgain && dontAskAgain.split('=')[1] === 'true') {
        console.log("Cookie 'dontAskAgainPush' encontrado. Função OnePushInit não será executada.");
        return;
    }

    if (user_logged_in == 'false') {
        console.log('Não mostre permissão de push! O usuário não está logado.')
        return;
    }

    window._oneSignalInitOptions.promptOptions = {
      slidedown: {
        prompts: [
          {
            type: "push",
            autoPrompt: true,
            text: {
              /* actionMessage limited to 90 characters */
              actionMessage: "Seu dia a dia é corrido? Deixe a gente te ajudar com lembretes na hora certa. Ative agora as notificações!",
              /* acceptButton limited to 15 characters */
              acceptButton: "ATIVAR AGORA",
              /* cancelButton limited to 15 characters */
              cancelButton: "LEMBRAR DEPOIS",
            },
            delay: {
              /* seconds to wait for display */
              timeDelay: 1,
              /* # pageviews for prompt to display */
              pageViews: 1,
            }
          }
        ]
      }
    }
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(function() {
      window.OneSignal.init(window._oneSignalInitOptions);
    });
}

const therapyCertified = document.getElementById('therapyCertified');
if (therapyCertified != null)
{
    setTimeout(function()
    {
        console.log("about to initialize OneSignal");
        OnePushInit();
    }, 39000);
}

document.addEventListener('DOMContentLoaded', () =>
{
    var pwaModal = document.getElementById('pwaModal');

    /*if (!window.matchMedia('(display-mode: standalone)').matches) {
        console.log('PWA já está instalado.');

        // Seleciona todos os links com [data-install-pwa] e faz as alterações
        /*document.querySelectorAll('[data-install-pwa]').forEach(link => {
            link.style.display = 'none';
            //link.setAttribute('href', '/');
        });

        if (pwaModal) {
            pwaModal.remove();
        }
    }

    else {*/

        if (therapyCertified != null)
        {
            if (!window.matchMedia('(display-mode: standalone)').matches)
            {
                if(pwaModal != null)
                {
                    setTimeout(function()
                    {
                        pwaModal = new bootstrap.Modal(pwaModal);
                        pwaModal.show();
                    }, 20000);
                }
            }

            else {
                if (pwaModal) {
                    pwaModal.remove();
                }
            }
        }
    //}
});

/*document.addEventListener('DOMContentLoaded', () => {
    if (!window.matchMedia('(display-mode: standalone)').matches) {
        // Se não estiver em modo standalone, provavelmente foi desinstalado
        localStorage.removeItem('pwaInstalled');
        console.log('PWA não está em modo standalone, item removido do localStorage');
    }
});*/

/**
 * PWA
 */
function setCookie(name, value, days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Verificar se o elemento com [data-remember-pwa] existe
var rememberPWAButton = document.querySelector('[data-remember-pwa]');
if (rememberPWAButton) {
    rememberPWAButton.addEventListener('click', function() {
        setCookie('rememberPWA', 'true', 20); // Cria um cookie por 1 semana
        console.log('Cookie "rememberPWA" criado por 1 semana');
    });
}

// Verificar se o elemento com [data-dont-ask-again-pwa] existe
var dontAskAgainPWAButton = document.querySelector('[data-dont-ask-again-pwa]');
if (dontAskAgainPWAButton) {
    dontAskAgainPWAButton.addEventListener('click', function() {
        setCookie('dontAskAgainPWA', 'true', 40); // Cria um cookie por 6 meses
        console.log('Cookie "dontAskAgainPWA" criado por 6 meses');
    });
}


if ('serviceWorker' in navigator && 'PushManager' in window) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js')
            .then(function(registration) {
                console.log('Service Worker registrado com sucesso:', registration);
            })
            .catch(function(error) {
                console.log('Falha ao registrar o Service Worker:', error);
            });

        var deferredPrompt;
        var installButtons = document.querySelectorAll('[data-install-pwa]');

        window.addEventListener('beforeinstallprompt', function(event) {
            event.preventDefault();
            deferredPrompt = event;

            installButtons.forEach(function(button) {
                button.style.display = 'block';
                button.addEventListener('click', function() {
                    deferredPrompt.prompt();
                    deferredPrompt.userChoice
                        .then(function(choiceResult) {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('Usuário aceitou a instalação do PWA');
                            } else {
                                console.log('Usuário rejeitou a instalação do PWA');
                            }
                            deferredPrompt = null;
                        });
                });
            });
        });

        window.addEventListener('appinstalled', function(event) {
            console.log('PWA instalado com sucesso');
            //localStorage.setItem('pwaInstalled', 'yes');
        });
    });
}




var swal = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
});


/*
// Inicialize as Tab após o carregamento do DOM
document.addEventListener('DOMContentLoaded', function() {
  var myTab = new bootstrap.Tab(document.getElementById('myTab'));
  myTab.show(); // Isso irá ativar a primeira aba, se desejar mostrar uma aba diferente, selecione o elemento correto aqui.
});*/


var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})


// Botão vídeo da terapia
const therapyModal = document.getElementById('therapyModal');
if (therapyModal) {
    therapyModal.addEventListener('show.bs.modal', function (e) {
        const button = e.relatedTarget
        const video = button.getAttribute('data-video')
        const iframe = therapyModal.querySelector('iframe');

        iframe.setAttribute('src', 'https://www.youtube.com/embed/' + video + '?rel=0');
    });
}

// Modal de detalhes de terapia
const detailsTherapyModal = document.getElementById('detailsTherapyModal');
if (detailsTherapyModal) {

    document.addEventListener('DOMContentLoaded', function() {
      const goToButton = document.getElementById('go-to');
      const ratingTabButton = document.getElementById('rating-tab');

      goToButton.addEventListener('click', function() {
        ratingTabButton.click(); // Simula o clique no botão da guia de avaliações
      });
    });

    detailsTherapyModal.addEventListener('show.bs.modal', function (e) {

        const descriptionTabButton = document.getElementById('description-tab');
        descriptionTabButton.click();

        const button      = e.relatedTarget;
        const product     = button.getAttribute('data-product');
        const details     = document.querySelector('[data-details="' + product + '"]');
        const description = document.querySelector('[data-description="' + product + '"]');
        const rating      = document.querySelector('[data-rating="' + product + '"]');
        const rate        = document.querySelector('[data-rate="' + product + '"]');
        const footer      = document.querySelector('[data-footer="' + product + '"]');

        if (details && document.getElementById('therapy-details')) {
            const modalBody = document.getElementById('therapy-details');
            modalBody.innerHTML = details.innerHTML;
        }

        if (description && document.getElementById('tabs-therapy-description')) {
            const modalBody = document.getElementById('tabs-therapy-description');
            modalBody.innerHTML = description.innerHTML;
        }

        if (rating && document.getElementById('tabs-therapy-rating')) {
            const modalBody = document.getElementById('tabs-therapy-rating');
            modalBody.innerHTML = rating.innerHTML;
        }

        if (rate && document.getElementById('tabs-therapy-rate')) {
            const modalBody = document.getElementById('tabs-therapy-rate');
            modalBody.innerHTML = rate.innerHTML;
            document.getElementById("rate-tab").style.display = 'block';
        } else document.getElementById("rate-tab").style.display = 'none';

        if (footer && document.getElementById('therapy-add-to-cart')) {
            const modalBody = document.getElementById('therapy-add-to-cart');
            modalBody.innerHTML = footer.innerHTML;
        }


        const stars = document.querySelectorAll('.star');
        let clickedIndex = -1;

        stars.forEach((star, index) => {
            star.addEventListener('mouseover', () => {
                for (let i = 0; i <= index; i++) {
                    stars[i].textContent = 'star';
                }
                for (let i = index + 1; i < stars.length; i++) {
                    stars[i].textContent = 'star_border';
                }
            });

            star.addEventListener('mouseout', () => {
                if (index > clickedIndex) {
                    for (let i = clickedIndex + 1; i < stars.length; i++) {
                        stars[i].textContent = 'star_border';
                    }
                }
            });

            star.addEventListener('click', () => {
                clickedIndex = index;
                const grade = star.getAttribute('data-grade');
                const gradeInput = star.closest('.stars').querySelector('.grade');

                if (gradeInput) {
                    gradeInput.value = grade;
                    //console.log(gradeInput);
                }

                //alert(`Grade: ${grade}`);
            });
        });

    });

    detailsTherapyModal.addEventListener('hidden.bs.modal', function (e) {
        const stars = document.querySelectorAll('.star');
        let clickedIndex = -1;

        stars.forEach((star, index) => {
            if (index > clickedIndex) {
                for (let i = clickedIndex + 1; i < stars.length; i++) {
                    stars[i].textContent = 'star_border';
                }
            }
        });

        // Ocultar o elemento .form-rate-success
        const formRateSuccess = document.querySelectorAll('.form-rate-success');
        formRateSuccess.forEach(element => {
            element.style.display = 'none';
        });
    });
}

// Modal de detalhes de emoção
const emotionDetails = document.getElementById('emotionDetails');
if (emotionDetails) {

    emotionDetails.addEventListener('show.bs.modal', function (e) {

        const button      = e.relatedTarget;
        const emotion     = button.getAttribute('data-id');
        const details     = document.querySelector('[data-details="' + emotion + '"]');

        if (details && document.getElementById('emotion-details')) {
            const modalBody = document.getElementById('emotion-details');
            modalBody.innerHTML = details.innerHTML;
        }

    });
}

// Modal para resgate de prêmio da gamificação
const redeemAward = document.getElementById('redeemAward');
if (redeemAward) {

    redeemAward.addEventListener('show.bs.modal', function (e) {

        const formRateSuccess = document.querySelectorAll('.form-purchase-award');
        formRateSuccess.forEach(element => {
            element.style.display = 'block';
        });

        const button      = e.relatedTarget;
        const award       = button.getAttribute('data-award');
        const details     = document.querySelector('[data-details="award-' + award + '"]');
        const footer      = document.querySelector('[data-footer="award-' + award + '"]');

        if (details && document.getElementById('award-details')) {
            const modalBody = document.getElementById('award-details');
            modalBody.innerHTML = details.innerHTML;
        }

        if (footer && document.getElementById('award-confirm-purchase-button')) {
            const modalBody = document.getElementById('award-confirm-purchase-button');
            modalBody.innerHTML = footer.innerHTML;
        }

    });

    redeemAward.addEventListener('hidden.bs.modal', function (e) {

        const formSuccessElements = document.querySelectorAll('.form-purchase-award');
        let isFormSuccessVisible = false;

        formSuccessElements.forEach(element => {
            if (element.style.display === 'none') {
                isFormSuccessVisible = true;
                return; // Se encontrou um elemento visível, não precisa continuar a verificação
            }
        });

        if (isFormSuccessVisible) location.reload();

        // Ocultar o elemento .form-success
        const formRateSuccess = document.querySelectorAll('.form-success');
        formRateSuccess.forEach(element => {
            element.style.display = 'none';
        });
    });
}


// Mostar detalhes dos pontuáveis
const showScorable = document.getElementById('showScorable');
if (showScorable) {

    showScorable.addEventListener('show.bs.modal', function (e) {

        const formRateSuccess = document.querySelectorAll('.form-purchase-scorable');
        formRateSuccess.forEach(element => {
            element.style.display = 'block';
        });

        const button      = e.relatedTarget;
        const scorable    = button.getAttribute('data-scorable');
        const details     = document.querySelector('[data-details="scorable-' + scorable + '"]');
        const footer      = document.querySelector('[data-footer="scorable-' + scorable + '"]');

        if (details && document.getElementById('scorable-details')) {
            const modalBody = document.getElementById('scorable-details');
            modalBody.innerHTML = details.innerHTML;
        }

        if (footer && document.getElementById('scorable-confirm-purchase-button')) {
            const modalBody = document.getElementById('scorable-confirm-purchase-button');
            modalBody.innerHTML = footer.innerHTML;
        }

        const stars = document.querySelectorAll('.star');
        let clickedIndex = -1;

        stars.forEach((star, index) => {
            star.addEventListener('mouseover', () => {
                for (let i = 0; i <= index; i++) {
                    stars[i].textContent = 'star';
                }
                for (let i = index + 1; i < stars.length; i++) {
                    stars[i].textContent = 'star_border';
                }
            });

            star.addEventListener('mouseout', () => {
                if (index > clickedIndex) {
                    for (let i = clickedIndex + 1; i < stars.length; i++) {
                        stars[i].textContent = 'star_border';
                    }
                }
            });

            star.addEventListener('click', () => {
                clickedIndex = index;
                const grade = star.getAttribute('data-grade');
                const gradeInput = star.closest('.stars').querySelector('.grade');

                if (gradeInput) {
                    gradeInput.value = grade;
                    //console.log(gradeInput);
                }

                //alert(`Grade: ${grade}`);
            });
        });

    });

    showScorable.addEventListener('hidden.bs.modal', function (e) {
        const stars = document.querySelectorAll('.star');
        let clickedIndex = -1;

        stars.forEach((star, index) => {
            if (index > clickedIndex) {
                for (let i = clickedIndex + 1; i < stars.length; i++) {
                    stars[i].textContent = 'star_border';
                }
            }
        });

        // Ocultar o elemento .description
        const description = document.querySelectorAll('.description');
        description.forEach(element => {
            element.style.display = 'block';
        });

        // Ocultar o elemento .form-rate
        document.getElementById('rate-therapy-pending').style.display = 'none';

        // Ocultar o elemento .form-rate-success
        const formRateSuccess = document.querySelectorAll('.form-rate-success');
        formRateSuccess.forEach(element => {
            element.style.display = 'none';
        });

    });
}


// Modal de login do dia
var loginGamification = document.getElementById('loginGamification');
if(loginGamification != null) {
    loginGamification = new bootstrap.Modal(loginGamification);
    loginGamification.show();
}

// Modal de passagem de nível
var changeLevel = document.getElementById('changeLevel');
if(changeLevel != null) {
    changeLevel = new bootstrap.Modal(changeLevel);
    changeLevel.show();
}

// Modal de adição de pontos e CALMAS
var morePoints = document.getElementById('morePoints');
if(morePoints != null) {
    morePoints = new bootstrap.Modal(morePoints);
    morePoints.show();
}

// Modal de alerta de formulário
var formModal = document.getElementById('formModal');
if(formModal != null) {
    let modal = new bootstrap.Modal(formModal);

    // Desabilitar o submit quanto exibir o modal
    formModal.addEventListener('show.bs.modal', (event) => {
        console.log('Show:', event)
        $(document).find('[type="submit"]').prop('disabled', true);
    });

    // Habilitar novamente o submit
    formModal.addEventListener('hidden.bs.modal', (event) => {
        console.log('Hide:', event)
        $(document).find('[type="submit"]').prop('disabled', false);
    });

    modal.show();

    console.log('Form modal!');
}

// Modal de alerta de formulário DASS 21
var dass21Modal = document.getElementById('dass21Modal');
if(dass21Modal != null) {
    dass21Modal = new bootstrap.Modal(dass21Modal);
    dass21Modal.show();
}

// Modal de alerta de formulário
var dass21ModalCare = document.getElementById('dass21ModalCare');
if(dass21ModalCare != null) {
    dass21ModalCare = new bootstrap.Modal(dass21ModalCare);
    dass21ModalCare.show();

    const confirmarBtn = document.querySelector('#modal-care-close');
    confirmarBtn.addEventListener('click', () => {
        var dass21ContentDisplay = document.querySelector("[data-dass21ContentResult='display']");
        dass21ContentDisplay.style.display = 'block';

        var dass21ContentPadding = document.querySelector("#therapyDass21");
        dass21ContentPadding.style.paddingTop = '2rem';
    });
}

// Modal de alerta de formulário de recorrência
var recurrenceModal = document.getElementById('recurrenceModal');
if(recurrenceModal != null) {
    recurrenceModal = new bootstrap.Modal(recurrenceModal);
    recurrenceModal.show();
}

// Fechar o offcanvas ao clicar em um link
const offcanvas = document.getElementById('offcanvasMainMenu');

if (window.history.replaceState) {
    window.history.replaceState(null, null, window.location.href);
}


var modal = document.getElementById('modal');
var span = document.getElementsByClassName("ic-close")[0];
window.onclick = function (event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
}


// Ler mais do ONline OFFline
function readMore() {
    var moreToRead = document.getElementById("moreToRead");
    var btnReadMore = document.getElementById("btnReadMore");

    if (moreToRead.style.display == "none") {
        moreToRead.style.display = "block";
        btnReadMore.innerHTML = "Recolher";
    } else {
        moreToRead.style.display = "none";
        btnReadMore.innerHTML = "Saber mais";
    }
}
window.readMore = readMore;


// Modal de detalhes de terapia
const emotionRegister = document.getElementById('emotionRegister');
if (emotionRegister)
{
    emotionRegister.addEventListener('hidden.bs.modal', function (e)
    {
        const formEmotionSuccess = document.getElementById('form-emotion-success');
        if (formEmotionSuccess.style.display != "none") location.reload();

        else
        {
            const healthDiarySelector = document.getElementById('health-diary-selector');
            const carousel = new bootstrap.Carousel(healthDiarySelector);
            carousel.to(0); // Define o slide ativo como o primeiro (índice 0)

            const nextButton = document.getElementById('next-button');
            nextButton.style.display = "block";
            nextButton.classList.add("border-next-button");

            const sendButton = document.getElementById('send-button');
            sendButton.style.display = "none";

        }
    });
}


/**
 * -----------------------------------------------------------------------------
 * jQuery
 * -----------------------------------------------------------------------------
 */
$(() => {

    var form_filter = document.getElementById('form_filter');
    if (form_filter) {

        $("#time_filter").on("click", function(e){

            var time_filter = $("#time_filter").val();

            if ( time_filter == 'custom' ) {
                $("#time_for_filters").removeClass("d-none");

                $("#from_filter").removeAttr("disabled");
                $("#until_filter").removeAttr("disabled");
            } else {
                $("#time_for_filters").addClass("d-none");

                $("#from_filter").attr("disabled", "");
                $("#until_filter").attr("disabled", "");
            }

        });
    }

    $(document).on('click', '.m-therapy-contributor .btn-therapy-details', function(e)
    {
        let tabTrigger = document.querySelector('#rate-tab');

        if(tabTrigger) {
            tabTrigger = new bootstrap.Tab(tabTrigger);
            tabTrigger.show();
        }
    });

    $(document).on('click', '#onesignal-slidedown-cancel-button', function(e)
    {
        setCookie('dontAskAgainPush', 'true', 90); // Cria um cookie por 6 meses
        console.log('Cookie "dontAskAgainPush" criado por 6 meses');
    });



    $('.data-table').DataTable({
        language: pt_BR,
        bFilter: false
    });

    $('.data-table-health-diary').DataTable({
        language: pt_BR
    });

    // Create DataTable
    var dataTable = $('.data-table-checkbox').DataTable({
        columnDefs: [{
            orderable: false,
            targets:   0,
            className: 'select-checkbox',
            checkboxes: {
                selectRow: true,
                selectCallback: function(e){
                    $('.data-table-checkbox').find('.dt-checkboxes:checked').each( function() {
                        var checkboxes = $(this);
                        var dump = checkboxes.parents('tr').data('id');
                        $("#contributors_id").val( dump + ', ' + $("#contributors_id").val() );

                        //console.log(dump);
                    });
                }
            },
        }],
        select: {
            style:    'os',
            selector: 'td:first-child'
        },
        language: pt_BR,
    });

    /**
     * -------------------------------------------------------------------------
     * Máscaras do formulário
     * -------------------------------------------------------------------------
     */
    function refreshMask()
    {
        let SPMaskBehavior = function(val) {
            if(!val) val = '';
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        }

        let spOptions = {
            onKeyPress: (val, e, field, options) => {
                field.mask(SPMaskBehavior.apply({}), options);
            }
        }

        $('.mask-card-name').off().on('keyup', function()
        {
            let val = $(this).val();
            $(this).val(val.toUpperCase());
        });

        $('.mask-phone').off().mask(SPMaskBehavior, spOptions);
        $('.mask-cpf').off().mask('000.000.000-00');
        $('.mask-date').off().mask('00/00/0000');
        $('.mask-card-date').off().mask('00/00/0000');
        $('.mask-card-validity').off().mask('00/0099');
        $('.mask-card-cvv').off().mask('000');
        $('.mask-card-number').off().mask('0000 0000 0000 0000');
    }

    refreshMask();

    // Modal pagamento rápido
    var therapyFastbuyModal = document.getElementById('therapyFastbuyModal');
    if(therapyFastbuyModal != null) {
        therapyFastbuyModal = new bootstrap.Modal(therapyFastbuyModal);

        let submit = $(document).find('[data-trigger="therapyFastBuy"]');
        let dateString;

        function b2b_get_calendar(id_therapist, id_therapy, time = null) {
            let container = $(document).find('.form-group-calendar');
            let table = container.find('.table-responsive');
            let selected = $('#schedule').val();

            table.addClass('processing-ajax');

            let data = {
                id_therapist: id_therapist,
                id_therapy: id_therapy,
            }

            // Inserir timestamp para verificar o dia
            if(time !== null) data.timestamp = time

            $.ajax({
                url: '/wp-ajax/calendar',
                async: false,
                data: data,

                success: (calendar) => {
                    container.html(calendar);

                    let dailyCalendar = container.find('.daily-calendar');

                    // Informar que não há horário mais para o mês atual
                    if(dailyCalendar && dailyCalendar.attr('data-enabled') === 'false') {
                        dailyCalendar.addClass('m-match-therapy').append('<div class="m-body-empty"><p>Opa, sem agenda este mês. <br> Aqui sempre tem alguém para te atender.<br><a href="'+ base_url +'/terapias">Escolha outra terapia</a></div>.');
                        $('[data-trigger="change-calendar-days"]').remove();
                        $('#calendarLabel').addClass('visually-hidden');
                        $('.therapy-duration').addClass('visually-hidden');
                        submit.text('Sem agenda este mês');
                    }

                    dateString = $(document).find('#field_schedule');

                    dateString.on('keyup', function() {
                        let val = $(this).val();

                        if(val) {
                            submit.removeClass('btn-dark');
                            submit.addClass('btn-success');
                            submit.removeAttr('disabled');
                            submit.text('Agendar agora');
                        }

                        else {
                            submit.removeClass('btn-success');
                            submit.addClass('btn-dark');
                            submit.attr('disabled', 'disabled');
                            submit.text('Escolha um horário');
                        }
                    });
                }

            });


            if(selected) {
                $(document).find('[data-value="'+ selected +'"]').each(function() {
                    $(this).addClass('active');
                });
            }
        }


        $(document).on('click', '[data-trigger="calendar-date-select"]', function()
        {
            let btn = $(this);
            let label = btn.data('label');
            let value = btn.data('value');

            $('[data-trigger="calendar-date-select"]').removeClass('active');
            btn.addClass('active');

            $('#calendarLabel').html(label);
            dateString.val(label).trigger('keyup');
            $('#field_calendar').val(value);

        });

        $(document).on('click', '[data-trigger="change-calendar-days"]', function()
        {
            let btn = $(this);
            let time = btn.data('time');
            let therapist = btn.data('author');
            let therapy = btn.data('id');

            b2b_get_calendar(therapist, therapy, time);
        });

        $(document).on('click', 'body.public [data-trigger="therapyFastbuyModal"]', function(e)
        {
            e.stopPropagation();
            e.preventDefault();

            let btn = $(this);
            let id = btn.data('id');

            $('#loginToByModal .btn-register').attr('href', base_url +'/registro/?tid='+ id);

            var loginToByModal = document.getElementById('loginToByModal');
            loginToByModal = new bootstrap.Modal(loginToByModal);
            loginToByModal.show();
        });

        // Ações ao abrir o modal
        $(document).on('click', 'body:not(.public) [data-trigger="therapyFastbuyModal"]', function()
        {
            // Abre o modal
            therapyFastbuyModal.show();

            let btn = $(this);
            let id = btn.data('id');
            let title = btn.data('title');
            let coupon = btn.data('coupon');
            let value = $('#post-'+id).find('.value-discount').html();
            let free = btn.attr('data-free');
            let therapist = btn.data('author');

            // Forçar o texto de seleção de horário
            submit.removeClass('btn-success');
            submit.addClass('btn-dark');
            submit.attr('disabled', 'disabled');
            submit.text('Escolha um horário');

            $('#therapyFastbuyModal .modal-body').addClass('processing-ajax');

            $.ajax({
                url: base_url + '/wp-admin/admin-ajax.php',
                method: 'POST',
                data: {
                    action: 'fastbuy_form',
                    free: free,
                },
                success: (data) => {
                    $('#therapyFastbuyModal .modal-body').removeClass('processing-ajax');
                    $('#therapyFastbuyModal .modal-form').html(data);

                    refreshMask();

                    // Caso não haja desconto pegar o valor normal
                    if(1 == 0
                        || !value
                        || value == ''
                        || typeof value == undefined
                    ) { value = $('#post-'+id).find('.value').html(); }

                    // Inserir informações da terapia no modal
                    $('#therapyFastbuyModal').find('#field_fastbuy_id').val(id);
                    $('#therapyFastbuyModal').find('#field_fastbuy_therapist').val(therapist);
                    $('#therapyFastbuyModal').find('.therapy-title').text(title);
                    $('#therapyFastbuyModal').find('.therapy-coupon').text(coupon);
                    $('#therapyFastbuyModal').find('.therapy-value').html(value.replace('por ', ''));

                    b2b_get_calendar(therapist, id);

                    if(!$('.daily-calendar').length) {
                        $('#calendarLabel').remove();
                    }
                }
            });

            // Desabilitar o botão após enviar o formulário
            $('#therapyFastbuyModal button[type="submit"]').off().on('click', function() {
                $(this).parents('form').trigger('submit');
                $(this).prop('disabled', true);
            });
        });
    }

    // Envia submit que troca Guia Coins por prêmio
    $(document).on('click', '.form-purchase-award .send-purchase-award-button', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Form
        var form = $(this).closest('.form-purchase-award');
        var formData = new FormData(form[0]);

        // Button
        var submit_button = $(this);
        var submit_button_spin = submit_button.find('.send-purchase-award-button-spin');
        submit_button.attr('disabled', true);
        submit_button_spin.show();

        // Success
        var success_return = $('.form-success');

        // Enviar o formulário via Ajax
        $.ajax({
            url: base_url +'/wp-json/v1/purchase-award',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function(response) {
                submit_button.removeAttr('disabled');
                submit_button_spin.hide();

                if (response.code == 'success') {
                    form.hide();
                    success_return.show();
                } else {
                    $('#return-award-msg').text(response.msg);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);
            }
        });
    });

    /*var cart_id = 0;

    $(document).on('click', '[data-pending-review]', function(event) {
        cart_id = $(this).data('cart-id');
    });*/


    $(document).on('click', '[data-pending-review]', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        var description    = $('.description');
        var form           = $('#rate-therapy-pending');
        var success_return = $('.form-rate-success');

        var therapy        = $(this);
        var cart_id        = therapy.data('cart-id');
        var inc            = therapy.data('inc');
        var order_id       = therapy.data('order-id');
        var product_id     = therapy.data('product-id');
        var therapy_name   = therapy.data('therapy-name');
        var author_name    = therapy.data('author-name');
        var author_id      = therapy.data('author-id');

        $('#rated-therapy-pending').val(product_id);
        $('#cart-id').val(cart_id);
        $('#inc').val(inc);
        $('#order-id').val(order_id);
        $('#product-id').val(product_id);
        $('#author-name-pending-therapy').text(author_name);


        description.hide();
        form.show();
        success_return.hide();
    });

    function enableSubmitButton(form) {
        var submit_button = form.find('[type="submit"]');
        submit_button.removeAttr('disabled');
    }

    // Envia comentário de avaliação do terapeuta
    $(document).on('submit', '#rate-therapy-pending', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Form
        var form           = $('#rate-therapy-pending');
        var formData       = new FormData(form[0]);
        var description    = $('.description');
        var success_return = $('.form-rate-success');

        // Button
        var submit_button = $(this);
        var submit_button_spin = submit_button.find('.send-rate-spin');
        var error_span = form.find('.return-error').first();

        // Verificação do valor de grade
        var grade = formData.get('grade');
        if (grade < 1 || grade > 5)
        {
            error_span.text('Por favor, selecione uma nota entre 1 e 5 estrelas.');
            enableSubmitButton(form); // Reativar o botão
            return;
        }

        else
        {
        submit_button.attr('disabled', true);
        submit_button_spin.show();

            error_span.text('');

        // Enviar o formulário via Ajax
        $.ajax({
            url: b2c_url +'/v1/add-therapist-comment',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function(response) {
                submit_button.removeAttr('disabled');
                submit_button_spin.hide();

                description.hide();
                form.hide();
                success_return.show();

                $('[data-review-cart-id="'+formData.get('cart_id')+'"]').remove();

                var pending = $('[data-review-cart-id]').length;

                if (pending == 0) $('.pendings').append('<div class="review"><b>Sem avaliações pendentes</b></div>');
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);
            }
        });

        // Atualiza pontos e nível
        $.ajax({
            url: base_url +'/wp-json/v1/reload-score-level',
            type: 'POST',
            //data: {},
            processData: false,
            contentType: false,
            success: function(response) {
                console.log(response);
            },
        });
        }
    });

    $(document).on('click', '[data-show-others-rates-pending]', function(event) {
        var description    = $('.description');
        var form           = $('#rate-therapy-pending');
        var success_return = $('.form-rate-success');

        description.show();
        form.hide();
        success_return.hide();
    });

    // Finaliza Onboarding da Gamificação
    $(document).on('click', '.m-general-onboarding [data-step], .m-general-onboarding [data-step-button]', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Pega o total de passos
        var total_steps = $('#general-onboarding').data('total-steps');


        // Pegue o step ativo
        if ($(this).data('step') != undefined)
        var step_active = $(this).data('step');

        else if ($("#general-onboarding .carousel-item.active") != undefined)
        var step_active = $("#general-onboarding .carousel-item.active").data('step-carousel');


        // Adiciona .disabled no botão voltar
        if (step_active == 0) $("#prev-button").addClass("disabled");
        else $("#prev-button").removeClass("disabled");


        // Muda para o botão de Submit
        if (step_active == total_steps) {
            $("#send-button").show();
            $("#next-button").hide();
            $("#next-button").removeClass("border-next-button");
        }
        else {
            $("#send-button").hide();
            $("#next-button").show();
            $("#next-button").addClass("border-next-button");
        }

    });

    // Enviar o formulário via Ajax
    $(".send-onboarding").on("click", function(e){

        // Button
        var send_button = $("#send-button");
        var send_button_spin = send_button.find('.send-spin');
        send_button.attr('disabled', true);
        send_button_spin.show();

        let type = $('#type-onboarding').val();

        $.ajax({
            url: base_url +'/wp-json/v1/'+ type +'-onboarding',
            type: 'POST',
            data: {finished:1},
            processData: false,
            contentType: false,
            success: function(response) {
                if (response.code == 'success') {
                    location.reload();
                }
                else {
                    $('#return-msg').text('Erro inesperado! Tente novamente mais tarde.');
                    send_button.removeAttr('disabled');
                    send_button_spin.hide();
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);

                $('#return-msg').text('Erro inesperado! Tente novamente mais tarde.');
                send_button.removeAttr('disabled');
                send_button_spin.hide();
            }
        });
    });

    // Envia comentário de avaliação do terapeuta
    $(document).on('submit', '#rate-therapy', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Form
        var form = $(this).closest('.form-rate');
        var formData = new FormData(form[0]);

        // Button
        var submit_button = $(this);
        var submit_button_spin = submit_button.find('.send-rate-spin');
        var error_span = form.find('.return-error').first();

        // Verificação do valor de grade
        var grade = formData.get('grade');
        if (grade < 1 || grade > 5)
        {
            error_span.text('Por favor, selecione uma nota entre 1 e 5 estrelas.');
            return;
        }

        else
        {
        submit_button.attr('disabled', true);
        submit_button_spin.show();

            error_span.text('');

        // Success
        var success_return = $('.form-rate-success');

        // Enviar o formulário via Ajax
        $.ajax({
            url: b2c_url +'/v1/add-therapist-comment',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function(response) {
                submit_button.removeAttr('disabled');
                submit_button_spin.hide();
                form.hide();
                success_return.show();

                console.log(response);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);
            }
        });
        }
    });

    /**
     * -------------------------------------------------------------------------
     * Ativar e desativar os usuários na plataforma
     * -------------------------------------------------------------------------
     */
    function activateUser(arr_id, status, multi = false)
    {
        $.ajax({
            url: '/wp-json/v1/activate-user',
            data: {
                users: arr_id,
                status: status,
            },
            method: 'GET',
            success: function(res)
            {
                // Caso seja somente um colaborador
                if(!multi) {
                    if(res == 1) {
                        $("#status-user-id-" + arr_id).html('<span class="badge rounded-pill bg-primary">Liberado</span>');
                    } else {
                        $("#status-user-id-" + arr_id).html('<span class="badge rounded-pill bg-danger">Removido</span>');
                    }
                }

                // Caso seja em lote
                else {
                    window.location.reload();
                }

            },
            error: function(res) {
                console.log('Erro:', res);
            },
        });
    }

    // Muda o estado de um único usuário por vez
    $(document).on('click', '.switch-button', function(e)
    {
        let btn = $(this);
        let user = btn.parents('tr');
        let user_id = user.data('id');
        let status = user.find('.badge.bg-primary').length;

        btn.attr('disabled', '');

        // Ativa ou desativa o colaborador
        activateUser(user_id, status);

        $("#switch-button-" + user_id).removeAttr('disabled');
    });

    // Muda o status em lote
    $('#formContributorPermition .dropdown-item [type="checkbox"]').on('click', function()
    {
        let btn = $(this);
        let action = btn.attr('id');

        // Verifica qual o botão que foi utilizado
        if(action == 'release-list') var status = 0;
        if(action == 'block-list') var status = 1;

        // Pega todos os colaboradores que serão modificados
        let arr_id = [];
        $('.data-table-checkbox').find('.dt-checkboxes:checked').each(function()
        {
            arr_id.push($(this).parents('tr').data('id'));
        });

        // Junta o array
        arr_id = arr_id.join(',');

        // Ativa ou desativa o colaborador
        activateUser(arr_id, status, true);
    });

    $(".card .progress").each(function() {

        var value = $(this).attr('data-value');
        var left = $(this).find('.progress-left .progress-bar');
        var right = $(this).find('.progress-right .progress-bar');

        if (value > 0) {
            if (value <= 50) {
                right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)');
            } else {
                right.css('transform', 'rotate(180deg)');
                left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)');
            }
        }
    });

    // Botão ler mais
    $('[data-trigger="readMore"]').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(this).data('expand')) {
            $('#shortText').removeClass('active');
            $('#fullText').addClass('active');
        }

        else {
            $('#fullText').removeClass('active');
            $('#shortText').addClass('active');
        }
    })

    function percentageToDegrees(percentage) {
        return percentage / 100 * 360;
    }

    $('a[href^="#"]').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        var link = $(this);
        var anchor = link.attr("href");
        var nav = link.parents('.navbar-nav');

        var headerHeight = $("header").height();

        var position = $(anchor).offset().top - headerHeight - 32;

        /*console.log($(anchor).offset().top);
        console.log(headerHeight);
        console.log(position);*/

        $("html, body, .app").scrollTop(position);

        nav.find('a').removeAttr('aria-current');
        link.attr('aria-current', 'page');

        var myOffCanvas = document.getElementById('offcanvasMainMenu');
        var bsOffCanvas = bootstrap.Offcanvas.getInstance(myOffCanvas);
        bsOffCanvas.hide();
    });

    // Remover tag de campo inválido ao selecionar para editar
    $('.is-invalid').on('focus', function () {
        $(this).parents('.form-group')
            .find('.is-invalid')
            .removeClass('is-invalid');
    });

    /**
     * -------------------------------------------------------------------------
     * Formatar nome para username
     * -------------------------------------------------------------------------
     */
    let validateString = (string) => {
        return string.replace(/ /g, '-')
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            .replace(/[<|,|>|\.|\?|\/|:|;|"|'|{|\[|}|\]|\||\\|~|`|!|@|#|\$|%|\^|&|\*|\(|\)|_|\+|=]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/(^-+|-+$)/, '')
            .toLowerCase()
    }

    $(document).on('keyup', '.mask-name', function()
    {
        const val = $(this).val();
        $('.mask-username').val(validateString(val));
    });

    /**
     * -------------------------------------------------------------------------
     * Links externos
     * -------------------------------------------------------------------------
     *
     * Lembre-se de inserir o ícone em CSS
     */
    $('body').find('header a[href*="//"], footer a[href*="//"]').each(function () {
        // Não fazer em links dentro do próprio domínio.
        var a = new RegExp('/' + window.location.host + '/');
        if (1 == 0
            || a.test(this.href)
            || $(this).hasClass('skip-external')
        ) { return true; }

        // Adicionar atributos "rel"
        $(this).attr('rel', 'external');
        $(this).attr('title', 'Site externo');
        $(this).attr('target', '_blank');
    });

    /**
     * -------------------------------------------------------------------------
     * Links externos
     * -------------------------------------------------------------------------
     *
     * Lembre-se de inserir o ícone em CSS
     */
    $('body').find('header a[href*="//"], footer a[href*="//"]').each(function()
    {
        // Não fazer em links dentro do próprio domínio.
        var a = new RegExp('/' + window.location.host + '/');
        if (1 == 0
            || a.test(this.href)
            || $(this).hasClass('skip-external')
        ) { return true; }

        // Adicionar atributos "rel"
        $(this).attr('rel', 'external');
        $(this).attr('title', 'Site externo');
        $(this).attr('target', '_blank');
    });

    /**
     * -------------------------------------------------------------------------
     * Copiar texto para área de transferência
     * -------------------------------------------------------------------------
     */
    function copyContent(input)
    {
        input.select();
        input.setSelectionRange(0, 99999);

        document.execCommand('copy');
    };

    $('[data-trigger="copy"]').on('click', function()
    {
        const btn = $(this);
        let input = btn.data('target');

        input = document.getElementById(input);

        copyContent(input);
    });

    let input = document.getElementById('registerLink');

    if(input) {
        input.addEventListener('click', function(e)
        {
            copyContent(e.currentTarget);
        });
    }

    /**
     * -------------------------------------------------------------------------
     * Confirmação para continuar formulário
     * -------------------------------------------------------------------------
     */
    $('.form-confirm input').on('click', function(e) {
        let val = $(this).val();
        let container = $(this).parents('.form-confirm');
        let confirm = container.data('confirm');
        let redirect = container.data('redirect');
        let txtTrue = container.find('input[value="1"] ~ .form-check-label').text();
        let txtFalse = container.find('input[value="0"] ~ .form-check-label').text();

        if(val === '0') {
            e.stopPropagation();
            e.preventDefault();

            swal.fire({
                icon: 'warning',
                text: confirm,
                showCancelButton: true,
                confirmButtonText: txtTrue,
                cancelButtonText: txtFalse,
            }).then(() => {
                window.location.href = redirect;
            });;
        }
    });

    /**
     * -------------------------------------------------------------------------
     * Formulário em carousel
     * -------------------------------------------------------------------------
     */
    const carouselId = '#formCarousel';
    if($(carouselId).length) {
        let carousel = document.getElementById('formCarousel');
        let bsCarousel = new bootstrap.Carousel(carouselId);
        let btnNext = $('[data-bs-slide="next"]');
        let btnPrev = $('[data-bs-slide="prev"]');
        let btnSubmit = $('.carousel-submit');
        let formPresentation = $('.form-presentation');

        $('.form-carousel').on('click', 'input', function(e) {
            setTimeout(() => {
                bsCarousel.next();
            }, 200);
        });

        carousel.addEventListener('slide.bs.carousel', (e) => {
            let container = $(e.target);
            let item = $(e.relatedTarget);
            let input = item.find('input').length;
            let enableNext = (!input || item.find('input:checked').length);
            let counter = (container.data('counter') - 1);
            let progress = e.to;
            let percent = ((progress * 100) / counter);
            let isPresentation = item.find('.form-group-presentation').length;

            // Último item do carrousel
            if(e.direction == 'left' && !e.to) {
                e.stopPropagation();
                e.preventDefault();

                btnSubmit.removeClass('visually-hidden');
                if(input) btnNext.addClass('disabled');

                progress = e.from;
                percent = 100;
            } else {
                btnSubmit.addClass('visually-hidden');
            }

            // Exibe ou esconde a apresentação
            if(formPresentation && isPresentation && percent < 100) {
                formPresentation.removeClass('visually-hidden');
                console.log('show: ', percent);
            } else if(formPresentation) {
                formPresentation.addClass('visually-hidden');
                console.log('hide: ', percent);
            }

            // Primeiro item do carousel
            if(e.direction == 'right' && !e.to) btnPrev.addClass('disabled');
            else btnPrev.removeClass('disabled');

            // Habilitar ir para o próximo item
            if(enableNext && percent < 100) btnNext.removeClass('disabled');
            else btnNext.addClass('disabled');

            // Barra de progresso
            $('.progress-bar').attr('aria-valuenow', progress);
            $('.progress-bar').attr('style', 'width:'+ percent +'%');
        });
    }

    /**
     * -------------------------------------------------------------------------
     * Desabilitar o submit após enviar o formulário
     * -------------------------------------------------------------------------
     */
    $('form').on('submit', function()
    {
        let button = $('form').find('[type="submit"]');

        console.log(button);
        button.prop('disabled', true);
    });

    /**
     * -------------------------------------------------------------------------
     * Processar formulários via ajax
     * -------------------------------------------------------------------------
     */
    $('.form-ajax').off().on('submit', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        let button = $(this).find('[type="submit"]');
        button.prop('disabled', true);

        let form = $(this);
        let data = form.serializeArray();
        var json_data = {};
        let loading = 'Aguarde e respire. sua solicitação está sendo processada.<br><br>Em caso de erro, chame o suporte, clicando no ícone de Whatsapp no canto da tela.';

        data = data.map((v) => [v.name, v.value]);

        let form_string = JSON.stringify(form.serialize());

        if(form_string.includes('_card')) {

            var cardData = {};
            var creditCard = {};
            var publicKey = '';
            var holder = '';
            var number = '';
            var expMonth = '';
            var expYear = '';
            var securityCode = '';
            var date = '';
            var use = false;
            var user_id = 0;

            for(var [key, value] of data) {
                if(!value) continue;

                if(key.includes('_user_id')) {
                    user_id = value;
                }

                if(key.includes('[use]') && value === 'true') {
                    use = true;
                }

                if(key.includes('[name]') && value) {
                    holder = value;
                }

                if(key.includes('[number]') && value) {
                    number = value.replaceAll(' ', '');
                }

                if(key.includes('[validity]') && value) {
                    date = value.split('/');
                    expMonth = date[0];
                    expYear = date[1];
                }

                if(key.includes('[cvv]') && value) {
                    securityCode = value;
                }
            }

            if(!use) {

                $.ajax({
                    async: false,
                    url: base_url + '/wp-admin/admin-ajax.php',
                    method: 'GET',
                    data: {
                        action: 'get_creditcard',
                        user_id: user_id,
                    },
                    success: (card_data) => {
                        if(card_data) {
                            card_data = JSON.parse(card_data);

                            if(card_data.publicKey) {
                                publicKey = card_data.publicKey;
                            }
                        }
                    }
                });
            }

            var creditCard = '';
            if(1 == 1
                && !use
                && holder
                && number
                && date
                && securityCode
            ) {
                cardData = {
                    publicKey: publicKey,
                    holder: holder,
                    number: number.replaceAll(' ', ''),
                    expMonth: expMonth,
                    expYear: (expYear.length == 4) ? expYear : "20"+ expYear,
                    securityCode: securityCode,
                };

                creditCard = PagSeguro.encryptCard(cardData);

                data.push(['encryptCard', creditCard.encryptedCard]);
            }
        }

        $.each(data, function () {
            json_data[this[0]] = this[1] || "";
        });

        if(form_string.includes('fastbuy')) {
            // Mensagem de Loading personalizada para pedidos
            loading = 'Seu pedido está sendo processado! :)';
            loading+= '<br><br>Isto pode levar um tempo. Aguarde alguns minutos, Respire, e confira se recebeu um e-mail de confirmação.';
            loading+= '<br><br>Caso não receba o e-mail, chame o suporte clicando no ícone do Whatsapp no canto da tela.';
        }

        Swal.fire({
            //icon: 'info',
            html: loading,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        $.ajax({
            url: base_url + '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: json_data,

            error: (xhr) => {
                data.icon = 'error';
                data.title = 'Opa, algo não deu certo...';
                data.html = 'Calma, vamos te ajudar. Chame o Suporte, clicando no ícone do Whatsapp no canto da tela.';

                // Rehabilitar o botão submit
                form.find('[type="submit"]').prop('disabled', false);

                swal.fire(data).then(() => {
                    console.log('Error:', xhr);
                });
            },

            success: (res) => {
                res = JSON.parse(res);

                if(res.refresh) {
                    window.location.reload();
                    return;
                }

                let data = {};

                data.icon = res.icon ?? 'success';
                if(res.title) data.title = res.title;
                if(res.content) data.html = res.content;
                if(res.btn) data.confirmButtonText = res.btn;

                if(res.err) {
                    data.icon = 'error';
                    data.title = res.title ?? 'Erro ao enviar o formulário!';

                    data.html = '<ul>';
                    res.err.forEach((txt) => {
                        data.html+= '<li>'+ txt +'</li>';
                    });
                    data.html+= '</ul>';

                    // Rehabilitar o botão submit
                    form.find('[type="submit"]').prop('disabled', false);
                }

                swal.fire(data).then(() => {
                    if(res.redirect) window.location.href = res.redirect;
                });

            },
            error: (res) => {
                console.log(res)
            }
        })
    });

    /**
     * -------------------------------------------------------------------------
     * Formulário com submit automático
     * -------------------------------------------------------------------------
     * /
    $('.form-auto-submit select, .form-auto-submit input, .form-auto-submit textarea').on('change', function() {
        let form = $(this).parents('.form');

        console.log($(this));

        form.trigger('submit');
    }); // */

    /*var therapyCat = $('#matchTherapy').data('category');
    if(therapyCat !== undefined) {

        let categoryDescription = $('#categoryDescription').text();

        shortText('#categoryDescription', categoryDescription);

        let module = $('#matchTherapy');

        $.ajax({
            url: base_url + '/wp-admin/admin-ajax.php',
            method: 'GET',
            data: {
                action: 'therapy_category',
                cat: therapyCat,
            },
            success: (res) => {
                let json = JSON.parse(res);

                if(!json) return;

                $('#categoryTitle').text(json.title);
                shortText('#categoryDescription', json.desc);
            }
        });
    }*/

    function shortText(element, text)
    {
        let html = '';
        html+= '<div id="shortText" class="short-text active">';
            html+= text.substr(0, 150);
            html+=' <a href="#" class="saiba-mais" data-expand="1">Saber mais</a>';
        html+='</div><!--/.short-text -->';

        html+='<div id="fullText" class="full-text">';
            html+= text;
            html+= ' <a href="#" class="saiba-mais" data-expand="0">Recolher</a>'
        html+='</div><!--/.short-text -->';

        $(element).html(html);

        $(element).find('.saiba-mais').off();
        $(element).find('.saiba-mais').on('click', function(e)
        {
            e.stopPropagation();
            e.preventDefault();

            if($(this).data('expand') == 1) {
                $('#shortText').removeClass('active');
                $('#fullText').addClass('active');
            }

            else {
                $('#fullText').removeClass('active');
                $('#shortText').addClass('active');
            }
        });
    }


    /**
     * -------------------------------------------------------------------------
     * Select emoji
     * -------------------------------------------------------------------------
     */
    $('.form-emoji input').on('click', function() {
        $(this).parents('.form-group-emoji').find('.form-emoji').addClass('unselected');
        $(this).parents('.form-emoji').removeClass('unselected');
    });


    // Enviar o formulário via Ajax
    $("[data-emotion]").on("click", function(e)
    {
        let emotion = $(this).data('emotion');

        let modal = $('#emotionRegister');

        modal = new bootstrap.Modal(modal);
        modal.show();

        let form = $('#form-health-diary-selector');
        let field = form.find('#field_' + emotion);
        field.prop('checked', true);

        field.parents('.form-group-emoji').find('.form-emoji').addClass('unselected');
        field.parents('.form-emoji').removeClass('unselected');
    });

    // Adiciona um manipulador de eventos para todos os checkboxes com a classe 'form-check-input'
    $('.reason-check-input').on('change', function()
    {
        var isChecked = $(this).prop('checked');

        var correspondingButton = $(this).closest('.btn');

        if (isChecked) {
            correspondingButton.removeClass('btn-outline-primary').addClass('btn-primary');
        } else {
            correspondingButton.removeClass('btn-primary').addClass('btn-outline-primary');
        }
    });


    $(document).on('change', '.form-group-card .form-check-use', function(e)
    {
        let check = $(this).val();
        let card_form = $('.form-group-card').find('.row');

        if(check == 'false') {
            card_form.addClass('active');
        }

        else {
            card_form.removeClass('active');
            card_form.find('input').val('');
        }
    });


    // Finaliza Onboarding da Gamificação
    $(document).on('click', '.m-emotion-register [data-step], .m-emotion-register [data-step-button]', function(event) {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Pega o total de passos
        var total_steps = $('#health-diary-selector').data('total-steps');


        // Pegue o step ativo
        if ($(this).data('step') != undefined)
        var step_active = $(this).data('step');

        else if ($(".carousel-item.active") != undefined)
        var step_active = $(".carousel-item.active").data('step-carousel');


        // Adiciona .disabled no botão voltar
        if (step_active == 0) $("#prev-button").addClass("disabled");
        else $("#prev-button").removeClass("disabled");

        // Muda para o botão de Submit
        if (step_active == total_steps) {
            $("#send-button").show();
            $("#next-button").hide();
            $("#next-button").removeClass("border-next-button");
        }
        else {
            $("#send-button").hide();
            $("#next-button").show();
            $("#next-button").addClass("border-next-button");
        }
    });

    // Envia comentário de avaliação do terapeuta
    $(document).on('click', '.m-emotion-register .send-emotion', function(event)
    {
        event.preventDefault(); // Impede o comportamento padrão do botão

        // Form
        var form = $('#form-health-diary-selector');
        var formData = new FormData(form[0]);

        // Button
        var submit_button = $(this);
        var submit_button_spin = submit_button.find('.send-spin');
        submit_button.attr('disabled', true);
        submit_button_spin.show();

        // Success
        var success_return = $('#form-emotion-success');

        // Enviar o formulário via Ajax
        $.ajax({
            url: base_url +'/wp-json/v1/add-feeling',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function(response) {
                submit_button.removeAttr('disabled');
                submit_button_spin.hide();
                form.hide();
                success_return.show();

                // exibe o contador de registros se recebeu potnos
                let award = $('#form-emotion-success > .award');
                //award.show();

                //if (response.count_records == '0')
                //{
                    award.show();
                    $.ajax({
                        url: base_url +'/wp-json/v1/reload-score-level',
                        type: 'POST',
                        //data: {},
                        processData: false,
                        contentType: false,
                        success: function(response) {
                            console.log(response);
                        },
                    });
                //}

                //else award.hide();

                $('#emotionRegister').on('hidden.bs.modal', function (e) {
                    location.reload();
                });

                console.log(response);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);
            }
        });
    });

    /**
     * Select post
     */
    $('.form-post').on('click', function()
    {
        $(this).parents('.form-col').find('.form-check-input').prop('checked', true);
    });

});
